// Reveal treatment as you scroll
//
// Fades and slides in elements as they appear in the viewport

let reveals;

const reveal = {

  // Init sticky headers
  init() {
    reveals = document.querySelectorAll('.reveal');

    if (!reveals) {
      return;
    }

    // Handle Reveals In View
    function imageObserver(entries, obs) {
      entries.forEach(function (entry) {
        if (entry.isIntersecting) {
          entry.target.classList.add("-active");
        }
      });
    }

    // Observer Options
    const options = {
      threshold: 0.25
    };

    // Create a new observer
    let observer = new IntersectionObserver(imageObserver, options);

    // Attach them to the observer
    reveals.forEach((reveal) => {
      observer.observe(reveal);
    });
  },

};

export default reveal
